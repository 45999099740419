import React from "react";
//import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import SectionWrapper from "./service.style";
import ScrollSomeItems from "../ScrollSomeItems";
import StrongerPayment from "../StrongerPayment";

const Payment = ({
  secTitleWrapper,
  secText,
  secHeading,
  Row,
  Col,
  lang = "He",
  item,
  paymeURL,
  reviews,
  onApprovePP,
  ModalCoupon,
}) => {
  const textLang = {
    topTitle: {
      He: "סיכום הזמנה",
      En: "Order Summary",
    },
    mainTitle: {
      He: "צעד אחרון לפני שתתחילו לטייל",
      En: "Lest step before starting travle",
    },

    cartSummery: {
      He: "סיכום הזמנה",
      En: "Order Summary",
    },
    price: {
      He: "המחיר",
      En: "The Price",
    },
  };

  return (
    <SectionWrapper style={{ direction: lang === "He" ? "rtl" : "ltr" }}>
      <Container className="containerPayment">
        <Box {...secTitleWrapper}>
          <Text {...secText} content={textLang["topTitle"][lang]} />
          <Heading {...secHeading} content={textLang["mainTitle"][lang]} />
        </Box>

        <Box {...Row}>
          <Box {...Col} key={`service-item-${1}`}>
            <ScrollSomeItems
              item={item}
              paymeURL={paymeURL}
              onApprovePP={onApprovePP}
              ModalCoupon={ModalCoupon}
            />
          </Box>
          <Box {...Col} key={`service-item-${1}`}>
            <StrongerPayment item={item} reviews={reviews} />
          </Box>
          {/*<Box>{<Testimonial allReviews={reviews} lang={"En"} />}</Box>*/}
        </Box>
      </Container>
    </SectionWrapper>
  );
};

Payment.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  Row: PropTypes.object,
  Col: PropTypes.object,
  serviceTitleStyle: PropTypes.object,
  secDes: PropTypes.object,
};

Payment.defaultProps = {
  secTitleWrapper: {
    mb: ["30px", "40px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#E14A1B", // "#ff4362",
    mb: "12px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "24px", "36px", "36px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    ml: "auto",
    mr: "auto",
    lineHeight: "1.12",
    width: "540px",
    maxWidth: "100%",
  },
  Row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
    ml: ["0", "-15px", "-20px", "-35px", "-35px"],
    mr: ["0", "-15px", "-20px", "-35px", "-35px"],
  },
  Col: {
    width: ["100%", "100%", "100%", "50%", "50%"],
    pl: ["15px", "15px", "25x", "35px", "35px"],
    pr: ["15px", "15px", "25px", "35px", "35px"],
    mb: "0px",
  },
  serviceTitleStyle: {
    fontSize: ["17px", "17px", "17px", "18px", "19px"],
    fontWeight: " 0",
    color: "#E14A1B", //"#ff4361",
    mb: 0,
    textAlign: "center",
  },
  secDes: {
    fontSize: ["14px", "15px", "16px"],
    color: "#5d646d",
    lineHeight: "1.875",
    mt: "30px",
    ml: "auto",
    mr: "auto",
    width: "470px",
    maxWidth: "100%",
    textAlign: "center",
  },
};

export default Payment;
